import React, { useState, useEffect } from 'react'
import { EmailShareButton, FacebookShareButton, TwitterShareButton } from "react-share"

export default () => {
  const [href, setHref] = useState(null)

  useEffect(
    () => {
      if (typeof window !== 'undefined') {
        setHref(window.location.href)
      }
    }
  )

  return (
    <nav className="share-icons nav">
      <EmailShareButton className="btn nav-link envelope" url={href} resetButtonStyle={false}>
        <i className="fas fa-envelope"></i>
      </EmailShareButton>

      <TwitterShareButton className="btn nav-link twitter" url={href} resetButtonStyle={false}>
        <i className="fab fa-twitter-square"></i>
      </TwitterShareButton>

      <FacebookShareButton className="btn nav-link facebook" url={href} resetButtonStyle={false}>
        <i className="fab fa-facebook-square"></i>
      </FacebookShareButton>
    </nav>
  )
}
