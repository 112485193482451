import React, { Component, createRef, isValidElement } from "react"
import { Link } from "gatsby"
import randomString from "random-string"
import jQuery from "jquery"
import "slick-carousel/slick/slick.min"
import reactHtmlParser from "html-react-parser"
import { FacebookShareButton, TwitterShareButton } from "react-share"

import Layout from "../components/layout"
import Iframe from "../components/iframe"
import Header from "../components/header"
import HeaderPost from "../components/header-post"
import Footer from "../components/footer"
import SEO from "../components/seo"
import MoveUp from "../components/move-up"
import Share from "../components/share"
import Gallery from "../components/gallery"
import ScrollSpy from "../components/scroll-spy"
import PostMeta from "../components/post-meta"
import wpShortCodes from "../services/wp-shortcodes"
import cleanUpQuotes from "../utils/cleanup-quotes"
import unescape from "../utils/unescape"
import { withTranslation } from "../services/i18next"
import gatsbyi18Context from "../contexts/gatsby-i18n-context.js"

import LogoImage from "../images/logo-header.svg"

class LongSingular extends Component {
  constructor(props) {
    super(props)

    this.content = createRef()
    this.ref = createRef()
    this.scrollHandler = this.scrollHandler.bind(this)
    this.state = {
      headerActive: false,
      headers: [],
      html: null,
      gallery: [],
      galleryIndex: 0,
    }
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      jQuery(window).on("scroll", this.scrollHandler)
    }

    const post = this.props.pageContext
    const { content } = post

    const { headers, html: updatedHtml } = (html => {
      const isBrowser = typeof window !== "undefined"

      if (isBrowser) {
        const element = document.createElement("div")
        const jQuery = require("jquery")

        element.innerHTML = html

        const headers = jQuery(element)
          .find("h1,h2,h3,h4,h5,h6")

        headers.each((i, el) => {
          jQuery(el).attr("id", randomString())
        })

        return {
          html: element.innerHTML,
          headers: headers
            .toArray()
            .map(el => ({
              tagName: el.tagName,
              name: el.innerHTML,
              id: el.id,
            })),
        }
      } else {
        return {
          html: "",
          headers: [],
        }
      }
    })(content)

    this.setState({
      headers,
      html: updatedHtml,
    })

    this.galleryImages()
  }

  galleryImages() {
    jQuery(document).on("click", ".wp-block-gallery figure", e => {
      const $target = jQuery(e.currentTarget)
      const $parentLi = $target.closest("li")
      const index = $parentLi.index()
      const gallery = $parentLi
        .parent()
        .children()
        .toArray()
        .map(el => {
          const $el = jQuery(el)
          const url = jQuery(el)
            .find("img")
            .data("full-url")
          const caption = jQuery(el)
            .find("figcaption")
            .text()

          return {
            url,
            caption,
          }
        })

      this.setState({
        gallery,
        galleryIndex: index,
      })
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const stateChange = !Object.is(this.state, prevState)
    const windowIsDefined = typeof window !== "undefined"

    if (windowIsDefined && stateChange) {
      const { html } = this.state

      if (html !== prevState.html) {
        jQuery(".slick-slider").each((idx, el) => {
          jQuery(el).imagesLoaded(() => {
            jQuery(el).slick({
              pauseOnFocus: false,
              pauseOnHover: false,
              slide: ".wp-block-eedee-block-gutenslide",
              prevArrow:
                '<button type="button" class="slick-prev pull-left"><i class="fa fa-angle-left" aria-hidden="true"></i></button>',
              nextArrow:
                '<button type="button" class="slick-next pull-right"><i class="fa fa-angle-right" aria-hidden="true"></i></button>',
            })

            jQuery(el).on("beforeChange", function() {
              el.style.backgroundImage = "url( '' )"
            })
          })
        })
      }
    }
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      jQuery(window).off("scroll", this.scrollHandler)
    }
  }

  scrollHandler() {
    if (typeof window === "undefined") {
      return null
    }

    const $window = jQuery(window)
    const scrollTop = $window.scrollTop()
    const $ref = jQuery(this.ref.current)
    const refTop = $ref.offset().top
    const { headerActive } = this.state

    const active = scrollTop >= refTop + 120

    if (active !== headerActive) {
      this.setState({
        headerActive: active,
      })
    }
  }

  findAndReplaceIframe(element) {
    if (!element) {
      return null
    }

    const { type, props } = element

    if (type === "iframe") {
      return <Iframe {...element.props} />
    } else {
      const children = element.props && element.props.children

      if (children) {
        if (isValidElement(element.props.children)) {
          return {
            ...element,
            props: {
              ...element.props,
              children: this.findAndReplaceIframe(children),
            },
          }
        } else if (element.props.children instanceof Array) {
          return {
            ...element,
            props: {
              ...element.props,
              children: children
                .filter(
                  child => isValidElement(children) || children instanceof Array
                )
                .map(child => this.findAndReplaceIframe(child))
                .map((child, i) => {
                  if (isValidElement(child)) {
                    return {
                      ...child,
                      key: i.toString(),
                    }
                  }

                  return child
                }),
            },
          }
        }
      }

      return element
    }
  }

  parse(inputString) {
    if (!inputString) {
      return null
    }

    const html = reactHtmlParser(inputString)

    return html instanceof Array
      ? html
          .filter(
            element => isValidElement(element) || element instanceof Array
          )
          .map(element => this.findAndReplaceIframe(element))
      : html
  }

  render() {
    const {
      headerActive,
      headers,
      html: givenHtml,
      gallery,
      galleryIndex,
    } = this.state
    const { i18n } = this.props
    const html = this.parse(cleanUpQuotes(givenHtml))

    const {
      type,
      title: givenTitle,
      excerpt,
      date,
      thumbnail,
      author: { name: author } = {},
    } = this.props.pageContext || {}

    const href = this.props.location ? this.props.location.href : ""
    const title = unescape(givenTitle)
    const featuredImage =
      thumbnail &&
      thumbnail.localFile &&
      thumbnail.localFile.childImageSharp &&
      thumbnail.localFile.childImageSharp.original.src

    const { pageContext } = this.props
    const { lang } = pageContext

    const data = {
      language: lang === 'en_US' ? 'en' : 'ar',
    }

    return (
      <gatsbyi18Context.Provider value={data}>
        <Layout className="has-header">
          <SEO
            title={title}
            image={featuredImage}
            type="article"
            description={excerpt}
          />
          {type === "post" ? <HeaderPost /> : <Header />}

          <MoveUp />

          {gallery.length ? (
            <Gallery
              index={galleryIndex}
              data={gallery}
              resetGallery={() => {
                this.setState({ gallery: [], galleryIndex: 0 })
              }}
            />
          ) : null}

          <section
            className={`header-container ${headerActive ? "active" : ""}`}
          >
            <header className="main-header">
              <nav className="navbar navbar-expand navbar-dark bg-dark">
                <Link
                  className="navbar-brand ml-4"
                  to={i18n.language === "en" ? "/en" : "/"}
                >
                  <img
                    className="d-block mb-0"
                    height="50"
                    src={LogoImage}
                    alt=""
                  />
                </Link>

                <div className="collapse navbar-collapse">
                  <ul className="navbar-nav mr-auto">
                    <li className={`nav-item mx-2`}>
                      <TwitterShareButton
                        className="btn nav-link"
                        url={href}
                        resetButtonStyle={false}
                      >
                        Tweet &nbsp;
                        <i className="fab fa-twitter" />
                      </TwitterShareButton>
                    </li>
                    <li className={`nav-item mx-2`}>
                      <FacebookShareButton
                        className="btn nav-link"
                        url={href}
                        resetButtonStyle={false}
                      >
                        Share &nbsp;
                        <i className="fab fa-facebook" />
                      </FacebookShareButton>
                    </li>
                  </ul>
                </div>
              </nav>
            </header>
          </section>

          <article ref={this.ref}>
            <div className="">
              <div className="d-flex flex-column align-items-center py-5">
                <header className="text-center mb-2">
                  <h1 className="display-4">{title}</h1>
                  {type === "post" && (
                    <section className="text-muted">
                      <PostMeta author={author} date={date} />
                    </section>
                  )}
                </header>
                <section className="w-100 d-flex flex-column align-items-center post-full-content position-relative">
                  <div className="d-flex justify-content-end mb-4">
                    <Share />
                  </div>

                  <div className="container-fluid">
                    <div className="row d-flex justify-content-center">
                      {headers && headers.length ? (
                        <div className="d-none d-xl-block col-xl-3">
                          <div className="scroll-spy-container w-100 d-flex justify-content-end pr-4">
                            <ScrollSpy headers={headers} />
                          </div>
                        </div>
                      ) : null}
                      <div className="col-12 col-xl-8">
                        <div
                          ref={this.content}
                          className="post-content mx-auto"
                        >
                          {html}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </article>
          <Footer />
        </Layout>
      </gatsbyi18Context.Provider>
    )
  }
}

export default withTranslation(LongSingular)
