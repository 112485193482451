import React, { Component } from 'react'
import jQuery from 'jquery'

export default class MoveUp extends Component {
  constructor (props) {
    super(props)

    this.handleScrolling = this.handleScrolling.bind(this)
    this.handleMoveUp = this.handleMoveUp.bind(this)

    this.state = {
      isActive: false
    }
  }

  handleMoveUp (e) {
    e.preventDefault()

    jQuery('html, body').animate({
      scrollTop: 0
    }, 4e2)
  }

  handleScrolling () {
    const $window = jQuery(window)
    const windowHeight = $window.height()
    const {isActive} = this.state

    const isActiveNewValue = $window.scrollTop() > windowHeight

    if (isActiveNewValue !== isActive) {
      this.setState({
        isActive: isActiveNewValue
      })
    }
  }

  componentDidMount () {
    if (typeof window !== 'undefined') {
      jQuery(window).on('scroll', this.handleScrolling)
    }
  }

  componentWillUnmount () {
    jQuery(window).off('scroll', this.handleScrolling)
  }

  render () {
    const {isActive} = this.state

    return (
      <div className={ `move-up small ${isActive ? 'active' : ''}` }>
        <button onClick={this.handleMoveUp} className="btn d-flex justify-content-center align-items-center">
          <i className="caret-up"></i>
        </button>
      </div>
    )
  }
}
