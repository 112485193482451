export default (text) => {
  if (typeof document === 'undefined') {
    return text
  }

  const el = document.createElement('div')
  el.innerHTML = text

  return el.innerText
}
