import React, {Component, createRef} from 'react'
import jQuery from 'jquery'
import _ from 'lodash'

export default class Iframe extends Component {
  constructor (props) {
    super(props)

    this.el = createRef()
    this.handleScrolling = _.throttle(
      this.handleScrolling.bind(this),
      1.5e2, {
        leading: true,
        trailing: false,
      }
    )

    this.state = {
      active: false
    }
  }

  handleResizing () {
    this.props['data-ratio']
    const ratio = this.props['data-ratio']

    if (ratio) {
      if (/\d+\:\d+/.test(ratio)) {
        const {groups} = ratio.match(/(?<x>\d+)\:(?<y>\d+)/)
        const x = +groups.x
        const y = +groups.y

        const $el = jQuery(this.el.current)

        $el.height(
          $el.width() * (y/x) + 60
        )
      }
    }
  }

  componentDidMount () {
    this.handleScrolling()
    jQuery(document).on('scroll', this.handleScrolling)
  }

  componentDidUpdate () {
    if (this.state.active) {
      this.handleResizing()
    }
  }

  handleScrolling (e) {
    const {active} = this.state
    const {current} = this.el
    const $current = jQuery(current)
    const documentScrollTop = Math.round( jQuery(document).scrollTop() )
    const windowHeight = jQuery(window).height()

    const elementBoundries = [
      Math.round( $current.offset().top ),
      Math.round( $current.offset().top + $current.height() ),
    ]

    const boundries = [
      documentScrollTop + windowHeight,
      documentScrollTop - windowHeight,
    ]

    const inBounds = elementBoundries[0] < boundries[0]
      && elementBoundries[1] > boundries[1]

    if (inBounds !== active) {
      if (!document.fullscreen) {
        this.setState({
          active: inBounds
        })
      }
    }
  }

  componentWillUnmount () {
    jQuery(document).off('scroll', this.handleScrolling)
  }

  render () {
    const {active} = this.state
    const {src, ...props} = this.props
    const ourSrc = active ? src : null

    return (
      <>
        <iframe ref={this.el} allowFullScreen={true} {...props} src={ourSrc}></iframe>
      </>
    )
  }
}
