import React, { useState, useEffect } from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

export default ({data, index, resetGallery}) => {
  const [photoIndex, setPhotoIndex] = useState(index)

  useEffect(
    () => {
      setPhotoIndex(index)
    }, [index]
  )

  if (!data.length) {
    return (null)
  }

  return (
    <div
      className="direction-ltr"
    >
      <Lightbox
        imagePadding={60}
        imageCaption={data[photoIndex].caption}
        mainSrc={data[photoIndex].url}
        nextSrc={data[(photoIndex + 1) % data.length].url}
        prevSrc={data[(photoIndex + data.length - 1) % data.length].url}
        onCloseRequest={() => resetGallery()}
        onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + data.length - 1) % data.length
            )
        }
        onMoveNextRequest={() =>
            setPhotoIndex(
              (photoIndex + 1) % data.length,
            )
        }
      />
    </div>
  )
}
