import React, { Component, createRef } from 'react'
import jQuery from 'jquery'

const insertHeaderInSet = (header, set = []) => {
  const headersOrder = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6']
  const setClone = [...set]

  if (!set.length) {
    setClone.push(header)

    return setClone
  }

  const lastItem = setClone[setClone.length - 1]
  const headerOrder = headersOrder.indexOf(header.tagName)
  const lastItemOrder = headersOrder.indexOf(lastItem.tagName)

  if (lastItemOrder >= headerOrder) {
    setClone.push(header)
  } else {
    lastItem.children = insertHeaderInSet(header, lastItem.children)
  }

  return setClone
}

const recurseActive = ({ ids = [], list = [] }) => {
  const listClone = [...list]

  for (const item of listClone) {
    const included = ids.includes(item.id)

    if (included) {
      item.active = true
    } else {
      item.active = false
    }

    item.children = recurseActive({ ids, list: item.children })
  }

  return listClone
}

const sortHeaders = (headers) => {
  let newHeaders = []

  for (const value of headers) {
    const { tagName } = value

    newHeaders = insertHeaderInSet(value, newHeaders)
  }

  return newHeaders
}

export default class extends Component {
  constructor(props) {
    super(props)

    this.ref = createRef()
    this.margin = 100
    this.state = {
      isSticky: false,
      sortedHeaders: sortHeaders(props.headers),
    }

    this.scrollHandler = this.scrollHandler.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  scrollHandler() {
    const $window = jQuery(window)
    const scrollTop = $window.scrollTop()
    const { sortedHeaders } = this.state

    const activeHeaders = this.props.headers
      .map(({ id }) => id)
      .reverse()
      .map(id => {
        const elOffset = jQuery(document)
          .find(`#${id}`)
          .offset()

        if (!elOffset) {
          return null
        }

        const top = elOffset.top

        if (scrollTop >= top - this.margin * 2) {
          return id
        }

        return null
      })
      .filter(value => !!value)

    this.setState({
      sortedHeaders: recurseActive({
        ids: activeHeaders[0],
        list: sortedHeaders,
      }),
    })
  }

  handleClick(e) {
    e.preventDefault()

    const $link = jQuery(e.currentTarget)
    const top = jQuery(document)
      .find($link.attr('href'))
      .offset().top

    jQuery('html, body').animate(
      {
        scrollTop: top - this.margin,
      },
      4e2
    )
  }

  componentDidMount() {
    jQuery(window).on('scroll', this.scrollHandler)
    jQuery(this.ref.current).on('click', 'a', this.handleClick)
  }

  componentWillUnmount() {
    if (typeof window === 'undefined') {
      return null
    }

    jQuery(window).off('scroll', this.scrollHandler)
    jQuery(this.ref.current).off('click', 'a', this.handleClick)
  }

  componentDidUpdate(prevProps) {
    if (!Object.is(prevProps.headers, this.props.headers)) {
      this.setState({
        sortedHeaders: sortHeaders(this.props.headers),
      })
    }
  }

  renderNavLink({ headers = [], first = false }) {
    if (!headers.length) {
      return null
    }

    return (
      <nav className={`nav ${first ? '' : 'mr-4 mb-2'} flex-column`}>
        {headers.map(({ name, id, active, children }, i) => (
          <div
            className={`nav-item ${active ? 'active' : ''}`}
            key={i.toString()}
          >
            <a
              href={`#${id}`}
              className="nav-link mb-0"
              dangerouslySetInnerHTML={{ __html: name }}
            />
            {this.renderNavLink({ headers: children })}
          </div>
        ))}
      </nav>
    )
  }

  render() {
    const { sortedHeaders } = this.state

    return (
      <div className="scroll-spy-scroller">
        <div ref={this.ref} className="scroll-spy">
          {this.renderNavLink({ headers: sortedHeaders, first: true })}
        </div>
      </div>
    )
  }
}
